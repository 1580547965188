.user_profile .user_images img{
   border-radius: 50% !important;
   outline: 3px solid #dc1f26;
   outline-offset: 2px;
}

.wallet input{
    border: 1px solid #dc1f26;
    padding: 9px;
    border-radius: 15px;
}
.wallet .wallet_btn{
    background-color: #dc1f26;
    color: #fff;
}

