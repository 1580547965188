
.Messagebox{
    position:sticky;
}
.Messagebox .top-person img{
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.allmessage{
    overflow-y: scroll;
    height: 275px;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .allmessage {
    scrollbar-width: auto;
    scrollbar-color: #8f54a0 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .allmessage::-webkit-scrollbar {
    width: 16px;
  }

  .allmessage::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .allmessage::-webkit-scrollbar-thumb {
    background-color: wheat;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
  .bottom_chat input{
    border: 1px solid wheat;
  }

.bottom_chat input:focus{
  border: 1px solid #bd0f0f;
  color: #bd0f0f;
}
.bottom_chat .icons i{
    font-size: 20px;
}






.chat{
  /* margin-top: auto; */
  margin-bottom: auto;
}
.card{
  /* height: 500px; */
  border-radius: 15px !important;
  /* background-color: rgba(0,0,0,0.4) !important; */
}
.contacts_body{
  padding:  0.75rem 0 !important;
  overflow-y: scroll;
  white-space: nowrap;
  overflow-x:visible;
  height: 430px;
}
.msg_card_body{
  overflow-y: auto;
  height: 250px !important;
}
.card-header{
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-footer{
border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.container{
  align-content: center;
}
.search{
  border-radius: 24px !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
}
.search:focus{
     box-shadow:none !important;
       outline:0px !important;
}
.type_msg{
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
  height: 50px !important;
  overflow-y: auto;
}
  .type_msg:focus{
     box-shadow:none !important;
       outline:0px !important;
}
.attach_btn{
border-radius: 15px 0 0 15px !important;
background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn{
border-radius: 0 15px 15px 0 !important;
background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.search_btn{
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts{
  list-style: none;
  padding: 0;
}
.chatapp{
  height: 500px !important;
}
.contacts li{
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
/* .active{
  background-color: rgba(0,0,0,0.3);
} */
.user_img{
  height: 50px;
  width: 50px;
  border:1.5px solid #f5f6fa;

}
.user_img_msg{
  height: 40px;
  width: 40px;
  border:1.5px solid #f5f6fa;

}
.img_cont{
  position: relative;
  height: 50px;
  width: 50px;
}
.img_cont_msg{
  height: 40px;
  width: 40px;
}
.online_icon{
position: absolute;
height: 15px;
width:15px;
background-color: #4cd137;
display: none !important;
border-radius: 50%;
bottom: 0.2em;
right: 0.4em;
border:1.5px solid white;
}
.offline{
background-color: #c23616 !important;
}
.user_info{
margin-top: auto;
margin-bottom: auto;
margin-left: 15px;
}
.user_info span{
font-size: 18px;
color:black;
}
.user_info p{
font-size: 10px;
color:#000;
}
.video_cam{
margin-left: 50px;
margin-top: 5px;
}
.video_cam span{
color: white;
font-size: 20px;
cursor: pointer;
margin-right: 20px;
}
.msg_cotainer{
margin-top: auto;
margin-bottom: auto;
margin-left: 10px;
border-radius: 25px;
background-color:wheat;
padding: 10px;
position: relative;
}
.msg_cotainer_send{
margin-top: auto;
margin-bottom: auto;
margin-right: 10px;
border-radius: 25px;
color: white;
background-color: #4cd137;
padding: 10px;
position: relative;
}
.msg_time{
position: absolute;
left: 0;
bottom: -15px;
color: rgba(255,255,255,0.5);
font-size: 10px;
}
.msg_time_send{
position: absolute;
right:0;
bottom: -15px;
color: rgba(255,255,255,0.5);
font-size: 10px;
}
.msg_head{
position: relative;
}
#action_menu_btn{
position: absolute;
right: 10px;
top: 10px;
color: white;
cursor: pointer;
font-size: 20px;
}
.action_menu{
z-index: 1;
position: absolute;
padding: 15px 0;
background-color: rgba(0,0,0,0.5);
color: white;
border-radius: 15px;
top: 30px;
right: 15px;
display: none;
}
.action_menu ul{
list-style: none;
padding: 0;
margin: 0;
}
.action_menu ul li{
width: 100%;
padding: 10px 15px;
margin-bottom: 5px;
}
.action_menu ul li i{
padding-right: 10px;

}
.action_menu ul li:hover{
cursor: pointer;
background-color: rgba(0,0,0,0.2);
}
@media(max-width: 576px){
.contacts_card{
margin-bottom: 15px !important;
}
}