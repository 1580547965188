.musta{
  color: #fff;
  outline: none;
  background-color: #bd0f0f;
  border-radius: 12px;
}

.swal2-popup{
  margin: auto !important;
}


#Sidebar{
  transition: 0.5s all !important;
}




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.button{
  background-color: #dc1f26;
  color: #fff;
  padding:8px 7px;
  border-radius: 4px;
  border: none;
}
.image-centre{
  margin-left: 31%;
}
/* Scrollbar Styling */
body::-webkit-scrollbar
{
  width: 10px;
}



.upcoming_project::-webkit-scrollbar,
.message_body::-webkit-scrollbar,
.quick_board::-webkit-scrollbar,
.msg_card_body::-webkit-scrollbar,
.contacts_body::-webkit-scrollbar{
  width: 5px;
 
}



.upcoming_project::-webkit-scrollbar-track,
.msg_card_body::-webkit-scrollbar-track,
.contacts_body::-webkit-scrollbar-track
{

  -webkit-border-radius: 10px;
  border-radius: 1px !important;
}


body::-webkit-scrollbar-track{

  -webkit-border-radius: 10px;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb,
.msg_card_body::-webkit-scrollbar-thumb,
.contacts_body::-webkit-scrollbar-thumb{
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #dc1f26; 
}
.activeNavbar{
  background-color: #fff !important;
  color: #dc1f26 !important;
}

.active6{
  background-color: #bd0f0f !important;
  color: #fff;
}

.rating6{
  color: yellow;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Navbar{
  position: fixed;
  height: auto;
 
}
.Navbar .dlabnav-scroll{
  overflow-y: scroll !important;
}



.dlabnav .metismenu li a:hover{
  background-color:#fff  !important;
  color: #000 !important;
}
/* .dlabnav .metismenu li a.active{
  background-color: red !important;
} */

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .Navbar .dlabnav-scroll {
    scrollbar-width: auto;
    scrollbar-color: #dc1f26 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .Navbar .dlabnav-scroll::-webkit-scrollbar {
    width: 0px;
  }

  .Navbar .dlabnav-scroll::-webkit-scrollbar-track {
    background:#dc1f26;
  }

  .Navbar .dlabnav-scroll::-webkit-scrollbar-thumb {
    background-color: wheat;
    border-radius: 10px;
    border: 3px solid #ffffff;
    display: none;
  }


.dlabnav{
  position:fixed;
  background-color: white !important;
  width: 260px !important
}

.content-body4{
  margin-left: 0 !important;
}



.switch {
  margin-top: 12px;
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #dc1f26;
}

input:focus + .slider {
  box-shadow: 0 0 1px #dc1f26;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.Navbar{
  background-color: #fff !important;
  /* width: 81% !important; */
  z-index: 99999;
}



.loader{
  display: inline-block;
  text-align: center;
  line-height: 86px;
  text-align: center;
  position: relative;
  padding: 0 48px;
  font-size: 48px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  }
  .loader:before, .loader:after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: currentColor;
    position: absolute;
    animation: load .7s infinite alternate ease-in-out;
    top: 0;
  }
  .loader:after {
    top: auto;
    bottom: 0;
  }
@keyframes load {
  0% {
    left: 0;
    height: 43px;
    width: 15px;
    transform: translateX(0)
  }
  50% {
    height: 10px;
    width: 40px
  }
  100% {
    left: 100%;
    height: 43px;
    width: 15px;
    transform: translateX(-100%)
  }
}

.btn-add{

  outline: none !important;
  color: #fff !important;

}


a{
  outline:none !important;
  text-decoration: none;
}

/* a:hover{
  color: #fff !important;
} */


/* 

.loadingContainer{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #dc1f26 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
} */


@media screen and (max-width:991px) {
  
  .chatMenu {
    position: absolute;
    top: -81px;
    background: #fff !important;
    z-index: 999;
  right:-100%;
    padding: 12px !important;
    border-radius: 12px !important;
    
  }

  #layout_container{
    margin-left: 0px !important;
  }

  .Navbar{
    width: 100% !important;
  }
  

  #vheelo_logo{
    display:none !important;
  }
  
  .chatactive{
    right: 0%;
  }

  .Navbar{
    z-index: 999999;
  }
  .main_dashboard .all_col {
    z-index: -1;
  }

}


