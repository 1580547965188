.h-100 {
    height: 100% !important;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-center {
    justify-content: center !important;
}

.authincation-content {
    background: #2953e8;
    border-radius: 5px;
    box-shadow: 0 0 35px 0 hsla(215, 9%, 64%, .15);
}

.no-gutters {
    margin-left: 0;
    margin-right: 0;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.no-gutters > .col, .no-gutters > [class*=col-] {
    padding-left: 0;
    padding-right: 0;
}

.auth-form {
    padding: 50px;
}

.text-center {
    text-align: center !important;
}

a {
    background-color: transparent;
    color: #2953e8;
    text-decoration: none;
}

a {
    color: #7e7e7e;
}

.text-white {
    color: #fff !important;
}

.text-center {
    text-align: center !important;
}

.form-group {
    margin-bottom: 1rem;
}

.mb-1, .my-1 {
    margin-bottom: .25rem !important;
}

.form-control {
    height: 41px;
}

.form-control {
    background: #fff;
    border: 1px solid #f0f1f5;
    border-radius: 0;
    color: #b1b1b1;
    height: 56px;
}.auth-form {
     padding: 50px;
 }.no-gutters>.col, .no-gutters>[class*=col-] {
      padding-left: 0;
      padding-right: 0;
  }.no-gutters {
       margin-left: 0;
       margin-right: 0;
   }.row {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
    }.authincation-content {
         background:#dc1f26;
         border-radius: 5px;
         box-shadow: 0 0 35px 0 hsla(215,9%,64%,.15);
     }.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto, .col-xxl, .col-xxl-1, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-auto {
          padding-left: 15px;
          padding-right: 15px;
          position: relative;
          width: 100%;
      }.form-group {
           margin-bottom: 1rem;
       }.mb-1, .my-1 {
            margin-bottom: .25rem!important;
        }.h-100 {
             height: 100%!important;
         }.col-md-6 {
              flex: 0 0 50%;
              max-width: 50%;
          }.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto, .col-xxl, .col-xxl-1, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-auto {
               padding-left: 15px;
               padding-right: 15px;
               position: relative;
               width: 100%;
           }div {
                display: block;
            }.no-gutters {
                 margin-left: 0;
                 margin-right: 0;
             }.row {
                  display: flex;
                  flex-wrap: wrap;
                  margin-left: -15px;
                  margin-right: -15px;
              }