
 .chat_inner {
    display: flex;
    flex-direction: column;
}
.message_top{
    display: flex;
}
.message_top img{
   border-radius: 50%;
}
.Messagebox .message_text{
    background-color:rgb(61, 59, 59);
    padding: 10px;
    color: #fff;
    border-radius: 20px;
    max-width: 300px;
    margin-right:15px;
}

.chat_inner.own{
    align-items: flex-end;
}

.chat_inner.own .message_text{
    background-color: #bd0f0f;
    color:#ffff;
}