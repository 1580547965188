.tabs .tabs_list{
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
} 
.tabs .tabs_list li{
    border: 1px solid #dc1f26;
    background-color: #fff;
    color: #dc1f26;
    padding: 12px 12px;
    border-radius: 24px;
}
.tabs .tabs_list .active{
  

    background-color: #dc1f26;
    padding: 12px 12px;
    color: #fff;
    /* border-radius: 12px; */
}


